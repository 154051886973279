// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
//  baseApiUrl: 'https://prodmobiato.nfpc.net/production/public/api',
 baseApiUrl: 'https://devmobiato.nfpc.net/merchandising/public/api',
//  baseApiUrl: 'https://presales.nfpc.net/production/public/api',

// baseApiUrl: 'http://10.225.60.139/production/public/api',



  // nfpcApiUrl: 'http://10.225.60.139/production/public/api',
  nfpcApiUrl: 'https://prodmobiato.nfpc.net/production/public/api',
  host: 'https://prodmobiato.nfpc.net',
  production: true
};

//dev url https://devmobiato.nfpc.net//merchandising/public/api
//prod url https://devmobiato.nfpc.net/production/public/api

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
